<template>
  <div class="pop encoder">
    <div class="popWrap">
      <div class="box one flexB popTitle">
        <h1>{{ $t("534") }}</h1>
        <a @click="handleClose">
          <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon"
        /></a>
      </div>
      <div class="box one flexB">
        <div class="flexL">
          <p class="bold">검색어</p>
          <input type="text" v-model="keyword" />
          <button class="small point" @click="getWowzaList">
            {{ $t("536") }}
          </button>
          <button class="small margin6" @click="$router.go()">
            {{ $t("537") }}
          </button>
        </div>
        <div class="flexL">
          <p class="bold">일자</p>
          <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span class="margin">~</span>
          <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          총 <span class="blue">{{ total }}</span
          >건
          <a :href="wowzaLink" target="_blank"
            ><button class="samll right">wowza server</button></a
          >
        </h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
          </div> -->
        <div class="tableBox">
          <table>
            <tr>
              <th>NO</th>
              <th>Status</th>
              <th>Date/TIme</th>
              <th>Event/Category</th>
              <th>Comment</th>
              <th>More Infomation</th>
            </tr>
            <tr v-for="(data, i) in logList" :key="i">
              <td>{{ total - i }}</td>
              <td>{{ data.severity }}</td>
              <td>{{ data.dateTime }}</td>
              <td>{{ data.event }}</td>
              <td>
                {{ data.comment }}
              </td>
              <td>{{ data.moreInfo }}</td>
            </tr>
            <!-- <tr>
              <td>121</td>
              <td>INFO</td>
              <td>2021-04-20 00:54:07 (KST)</td>
              <td>connect-pending session (100)</td>
              <td>-</td>
              <td>
                x-ctx:46.105.53.249,x-vhost:_defaultVHost_,x-duration:0.557,s-ip:[any],s-port:1935,s-uri:rtmp://221.146.70.207:1935/flash,c-ip:46.105.53.249,c-proto:rtmp,c-user-agent:LNX
                9,0,124,2,c-client-id:2106832267,cs-bytes:3289,sc-bytes:3073,cs-uri-stem:rtmp://221.146.70.207:1935/flash
              </td>
            </tr>
            <tr>
              <td>121</td>
              <td>INFO</td>
              <td>2021-04-20 00:54:07 (KST)</td>
              <td>connect-pending session (100)</td>
              <td>-</td>
              <td>
                x-ctx:46.105.53.249,x-vhost:_defaultVHost_,x-duration:0.557,s-ip:[any],s-port:1935,s-uri:rtmp://221.146.70.207:1935/flash,c-ip:46.105.53.249,c-proto:rtmp,c-user-agent:LNX
                9,0,124,2,c-client-id:2106832267,cs-bytes:3289,sc-bytes:3073,cs-uri-stem:rtmp://221.146.70.207:1935/flash
              </td>
            </tr>
            <tr>
              <td>121</td>
              <td>WARN</td>
              <td>2021-04-20 00:54:07 (KST)</td>
              <td>connect-pending session (100)</td>
              <td>
                Application folder ([install-location]/applications/flash) is
                missing
              </td>
              <td>x-vhost:_defaultVHost_,x-duration:2468062.598</td>
            </tr>
            <tr>
              <td>121</td>
              <td>INFO</td>
              <td>2021-04-20 00:54:07 (KST)</td>
              <td>connect-pending session (100)</td>
              <td>-</td>
              <td>
                x-ctx:46.105.53.249,x-vhost:_defaultVHost_,x-duration:0.557,s-ip:[any],s-port:1935,s-uri:rtmp://221.146.70.207:1935/flash,c-ip:46.105.53.249,c-proto:rtmp,c-user-agent:LNX
                9,0,124,2,c-client-id:2106832267,cs-bytes:3289,sc-bytes:3073,cs-uri-stem:rtmp://221.146.70.207:1935/flash
              </td>
            </tr>
            <tr>
              <td>121</td>
              <td>INFO</td>
              <td>2021-04-20 00:54:07 (KST)</td>
              <td>connect-pending session (100)</td>
              <td>-</td>
              <td>
                x-ctx:46.105.53.249,x-vhost:_defaultVHost_,x-duration:0.557,s-ip:[any],s-port:1935,s-uri:rtmp://221.146.70.207:1935/flash,c-ip:46.105.53.249,c-proto:rtmp,c-user-agent:LNX
                9,0,124,2,c-client-id:2106832267,cs-bytes:3289,sc-bytes:3073,cs-uri-stem:rtmp://221.146.70.207:1935/flash
              </td>
            </tr> -->
          </table>
        </div>

        <!-- <div class="pagination">
          <el-pagination small layout="prev, pager, next" :total="100">
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchWowzaList } from "@/api/file";
export default {
  components: { Datepicker },
  name: "Home",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: "",
      endYmd: "",
      keyword: "",
      currentpage: 0,
      total: 0,
      size: 0,
      logList: [],
      wowzaLink: "",
    };
  },
  mounted() {
    this.wowzaLink = this.$route.query.link;
    this.getWowzaList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleClose() {
      window.close();
    },
    getWowzaList() {
      let params = {
        keyword: this.keyword,
        dateRange: {
          from: this.startYmd,
          to: this.endYmd,
        },
      };

      fetchWowzaList(params).then((res) => {
        this.total = res.data.data.total;
        this.logList = res.data.data.content;
      });
    },
  },
};
</script>
